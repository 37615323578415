
import * as aroraProviderRuntime$uMmxeKNBUk from 'D:/deploy/UploadHidden/20241212-T903.516/arora-front/satellite/build-rest-4d906682-de7e-42b9-af9b-fcade667cfa6/providers/aroraProvider.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "aroraProvider",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['aroraProvider']: { provider: aroraProviderRuntime$uMmxeKNBUk, defaults: {} }
}
        